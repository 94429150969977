import React from 'react';
import Wrapper from '../layout/wrapper';
import { ButtonLink } from '../layout/buttons';
import './services.scss';

const Services = () => (
  <section id='services-full'>
    <Wrapper center>
      <div className='services--grid'>
        <article className='service-single'>
          <div className='content'>
            <h3>Kundereiser</h3>
            <p>
              Vi ser på hele kundereisen for å finne hvor
              forbedringspotensialene deres ligger.
            </p>
          </div>
          <ul className='service-categories'>
            <li>
              <span>Kartlegging av og strategi for digitale kundereiser</span>
            </li>
            <li>
              <span>Målgruppestrategi og personas</span>
            </li>
            <li>
              <span>Analyse</span>
            </li>
            <li>
              <span>CX</span>
            </li>
          </ul>
        </article>
        <article className='service-single'>
          <div className='content'>
            <h3>Digitale kanaler</h3>
            <p>
              Vi hjelper deg med å optimalisere dine digitale kanaler og din
              digitale markedsføring.
            </p>
          </div>
          <ul className='service-categories'>
            <li>
              <span>Performance marketing</span>
            </li>
            <li>
              <span>Analyser og optimalisering</span>
            </li>
            <li>
              <span>Always-on og kampanjer</span>
            </li>
            <li>
              <span>CRM, førstepartsdata og ID</span>
            </li>
            <li>
              <span>GDPR</span>
            </li>
            <li>
              <span>Optimalisering av nettside/landingssider</span>
            </li>
            <li>
              <span>Utleie av konsulenter</span>
            </li>
          </ul>
        </article>
        <article className='service-single'>
          <div className='content'>
            <h3>Management consulting</h3>
            <p>
              Vi hjelper dere med å identifisere muligheter og øke kompetanse
              internt.
            </p>
          </div>
          <ul className='service-categories'>
            <li>
              <span>Inhousing</span>
            </li>
            <li>
              <span>Organisering</span>
            </li>
            <li>
              <span>Opplæring</span>
            </li>
            <li>
              <span>Digital forretningsutvikling og produktutvikling</span>
            </li>
          </ul>
        </article>
      </div>
      <ButtonLink to='/kontakt' text='Skal vi jobbe sammen?' />
    </Wrapper>
  </section>
);

export default Services;
