import React from 'react';
import Layout from '../components/layout/layout';
import { TopSection } from '../components/topsection';
import Wrapper from '../components/layout/wrapper';
import Services from '../components/services';
import SEO from '../components/seo'

const ServicesPage = () => (
  <Layout>
    <SEO title={'Tjenester'} />
    <TopSection title='Trenger du hjelp med å vokse?' orange='2' />
    <Wrapper center>
      <p className='extend-h3'>
        I Caulis er vi opptatt av mennesker, drevet av kunnskap. Vi jobber med markedsføring, annonseteknologi og personvern. Dette gjør vi gjennom konsulenttjenester og gjennom mediebyråtjenester.
      </p>
    </Wrapper>
    <Services />
  </Layout>
);

export default ServicesPage;
